import logo from './logo.svg';
import Picture1 from './Picture1.png'
import Picture2 from './Picture2.png'
import Arrow from './arrow.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <div className='Header'><h1>Welcome to the PH R&D Digital Curriculum Platform​</h1>
      <div className='DescPara'>Embark on your digital learning journey today!​<br/>
      Click on the tiles below to discover our various training options & book yourself a seat in the offered courses 
</div>
      </div>
      <div className='Boxes'>
      <div className='blank'></div>
      <div className='LeftDiv'><div className='picture1'>
        <img className="Picture1_img" src={Picture1}/></div>
        <div className='Text'>
        <h3>Access to whole Coursera catalogue</h3>
        <ul>
          <li>Find the right learning offer tailored to your needs for your individual development</li>
          <li>Build your skills on one of the world class learning platform, Coursera. Coursera for Business provides you with the world’s best learning experience with courses taught by industry and academic experts</li>
        </ul>
        </div>
        <div className='ButtonDiv'>
        <a href='https://www.coursera.org/programs/pharma-r-d-c2bss?authProvider=bayer'>
        <div className='Button1'><div className="ButtonText">Click here to access PH R&D Coursera for business </div><div className='Arr'><i class="arrow right"></i></div>
        
        
        </div>
        </a>
        </div>
        
        </div>
      <div className='RightDiv'><div className='picture2'><img className="Picture2_img" src={Picture2}/></div>
      <div className='Text'>
        <h3>Access to pre-selected courses for Digital Experts​</h3>
        <ul>
          <li>Develop state-of-the-art <string>technological knowledge</string> and skills to stay up-to-date with latest digital trends and technologies​ </li>
          <li>Access to a pre-selected range of courses from a variety of providers (selected based on high-quality and usefulness)
</li>
<li>Courses from variety of industry-leading providers, such as Coursera, Udemy, Udacity, EdX and others. </li>
        </ul>
        </div>
        <div className='ButtonDiv'>


        <a href='https://dtcurriculum.int.bayer.com/'>
        <div className='Button2'><div className="ButtonText">Click here to access DT Curriculum</div><div className='Arr'><i class="arrow right"></i></div>
        </div></a>
        
      </div>
      
      </div>
      </div>
    </div>
  );
}

export default App;
